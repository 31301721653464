<template>
	<div v-if="withLayout" :class="containerClass" @click="onWrapperClick">
		<AppTopBar @menu-toggle="onMenuToggle" />

        <transition name="layout-sidebar">
            <div :class="sidebarClass" @click="onSidebarClick" v-show="isSidebarVisible()">
                <div class="layout-logo">
                    <router-link to="/">
                        <img alt="Logo" :src="logo" />
                    </router-link>
                </div>

                <AppProfile />

                <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />

            </div>
        </transition>

		<div class="layout-main">
			<router-view />
		</div>

		<AppFooter />
	</div>
    <div v-else><router-view /></div>
</template>

<script>
import AppTopBar from './components/AppTopbar.vue';
import AppProfile from './components/AppProfile.vue';
import AppMenu from './components/AppMenu.vue';
import AppFooter from './components/AppFooter.vue';

export default {
    data() {
        return {
            withLayout: false,
            layoutMode: 'static',
            layoutColorMode: 'dark',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            menu : [],
        }
    },
    watch: {
        $route() {
            
            this.withLayout = !(this.$route.matched.some(record => record.meta.freeLayout));
            this.menuActive = false;
            this.$toast.removeAllGroups();

            /* #region MONTAGEM DO MENU */

            const user = this.$auth.currentUser;

            if(user != null) {

                let idx = 0;

                this.menu[idx++] = {label: 'Início', icon: 'pi pi-fw pi-th-large', to: '/'};
                
                if(this.$auth.userCan("USUARIOS_VISUALIZAR")) {
                    this.menu[idx] = {label: 'Usuários', icon: 'pi pi-fw pi-users', items: []};

                    this.menu[idx].items.push({label: 'Novo / Todos', icon: 'pi pi-fw pi-user', to: '/usuarios'});

                    if(user.permissao == 1)
                        this.menu[idx].items.push({label: 'Permissões', icon: 'pi pi-fw pi-lock', to: '/permissoes'});

                    idx++;
                }


                if(this.$auth.userCan("SEGURADORAS_VISUALIZAR")) {
                    this.menu[idx] = {label: 'Seguradoras', icon: 'pi pi-fw pi-globe', items: []};
                    this.menu[idx].items.push({label: 'Todas', icon: 'pi pi-fw pi-globe', to: '/seguradoras'});

                    if(this.$auth.userCan("SEGURADORAS_CADASTRAR"))
                        this.menu[idx].items.push({label: 'Nova', icon: 'pi pi-fw pi-globe', to: '/seguradora'});

                    idx++;
                }

                if(this.$auth.userCan("IMOBILIARIAS_VISUALIZAR")) {
                    this.menu[idx] = {label: 'Imobiliárias', icon: 'pi pi-fw pi-briefcase', items: []};
                    this.menu[idx].items.push({label: 'Todas', icon: 'pi pi-fw pi-briefcase', to: '/imobiliarias'});

                    if(this.$auth.userCan("IMOBILIARIAS_CADASTRAR"))
                        this.menu[idx].items.push({label: 'Nova', icon: 'pi pi-fw pi-briefcase', to: '/imobiliaria'});

                    idx++;
                }

                if(this.$auth.userCan("PESSOAS_*")) {
                    this.menu[idx] = {label: 'Pessoas', icon: 'pi pi-fw pi-id-card', items: []};

                    if(this.$auth.userCan("PESSOAS_CADASTRAR"))
                        this.menu[idx].items.push({label: 'Nova', icon: 'pi pi-fw pi-id-card', to: '/pessoa'});
                    
                    if(this.$auth.userCan("PESSOAS_VISUALIZAR"))
                        this.menu[idx].items.push({label: 'Todas', icon: 'pi pi-fw pi-id-card', to: '/pessoas'});

                    if(user.permissao == 1) {
                        this.menu[idx].items.push({label: 'Vínculos Empregatícios', icon: 'pi pi-fw pi-star', to: '/pessoas-vinculos-empregaticios'});
                        this.menu[idx].items.push({label: 'Estados Civis', icon: 'pi pi-fw pi-star', to: '/pessoas-estados-civis'});
                        this.menu[idx].items.push({label: 'Ramos de Atividades', icon: 'pi pi-fw pi-star', to: '/pessoas-ramos-atividades'});
                        this.menu[idx].items.push({label: 'Situações de Residências', icon: 'pi pi-fw pi-star', to: '/pessoas-residencias-situacoes'});
                    }

                    idx++;
                }

                if(this.$auth.userCan("IMOVEIS_*")) {
                    this.menu[idx] = {label: 'Imóveis', icon: 'pi pi-fw pi-home', items: []};

                    if(this.$auth.userCan("IMOVEIS_CADASTRAR"))
                        this.menu[idx].items.push({label: 'Novo', icon: 'pi pi-fw pi-home', to: '/imovel'});
                    
                    if(this.$auth.userCan("IMOVEIS_VISUALIZAR") || this.$auth.userCan("IMOVEIS_EXCLUIR"))
                        this.menu[idx].items.push({label: 'Todos', icon: 'pi pi-fw pi-home', to: '/imoveis'});

                    idx++;
                }

                if(this.$auth.userCan("SEGURO_FIANCA_VISUALIZAR")) {
                    this.menu[idx] = {label: 'Seguro Fiança', icon: 'pi pi-fw pi-file', items: []};

                    if(this.$auth.userCan("SEGURO_FIANCA_CADASTRAR"))
                        this.menu[idx].items.push({label: 'Nova Análise', icon: 'pi pi-fw pi-file', to: '/processo'});

                    this.menu[idx].items.push({label: 'Todas as Análises', icon: 'pi pi-fw pi-file', to: '/processos'});
                    this.menu[idx].items.push({label: 'Apólices Emitidas', icon: 'pi pi-fw pi-file', to: '/processos/emitidos'});

                    if(user.permissao == 1) {
                        this.menu[idx].items.push({label: 'Finalidades de Locações', icon: 'pi pi-fw pi-star', to: '/locacoes-finalidades'});
                        this.menu[idx].items.push({label: 'Motivos de Locações', icon: 'pi pi-fw pi-star', to: '/locacoes-motivos'});
                    }

                    idx++;

                }

                if(this.$auth.userCan("CAPITALIZACAO_VISUALIZAR")) {
                    
                    this.menu[idx] = {label: 'Capitalização', icon: 'pi pi-fw pi-file', items: []};

                    if(this.$auth.userCan("CAPITALIZACAO_CADASTRAR"))
                        this.menu[idx].items.push({label: 'Novo Título', icon: 'pi pi-fw pi-file', to: '/capitalizacao'});

                    this.menu[idx].items.push({label: 'Todos os Títulos', icon: 'pi pi-fw pi-file', to: '/capitalizacoes'});

                    idx++;

                }

                this.menu[idx++] = {label: 'Imobiliário', icon: 'pi pi-external-link', url: 'https://calculo.alfaseguradora.com.br', target: "_blank" };

                if(this.$auth.userCan("TICKETS_*")) {

                    this.menu[idx] = {label: 'Tickets', icon: 'pi pi-fw pi-comments', to: '/tickets'};

                    idx++;
                    
                }

            }

            /* #endregion */

        }
    },
    methods: {
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
					if(this.mobileMenuActive === true) {
						this.overlayMenuActive = true;
					}

                    this.overlayMenuActive = !this.overlayMenuActive;
					this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
		onLayoutColorChange(layoutColorMode) {
			this.layoutColorMode = layoutColorMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth > 1024;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
                else
                    return true;
            }
            else {
                return true;
            }
        },
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': this.$appState.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.ripple === false
            }];
        },
        sidebarClass() {
            return ['layout-sidebar', {
                'layout-sidebar-dark': this.layoutColorMode === 'dark',
                'layout-sidebar-light': this.layoutColorMode === 'light'
            }];
        },
        logo() {
            return (this.layoutColorMode === 'dark') ? "assets/layout/images/logo-jacyara-seguros-branca.svg" : "assets/layout/images/logo-jacyara-seguros.svg";
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppProfile': AppProfile,
        'AppMenu': AppMenu,
        'AppFooter': AppFooter,
    }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
