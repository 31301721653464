<template>
	<div class="layout-topbar">
		<button class="p-link layout-menu-button" @click="onMenuToggle">
			<span class="pi pi-bars"></span>
		</button>
		<div class="layout-topbar-icons">
			<button class="p-link" @click="$refs.op.toggle($event)">
				<span class="layout-topbar-item-text">Events</span>
				<span class="layout-topbar-icon pi pi-bell"></span>
				<span class="layout-topbar-badge">{{ notificacoes_novas }}</span>
			</button>
			<!-- <span class="layout-topbar-search">
				<InputText type="text" placeholder="Search" />
				<span class="layout-topbar-search-icon pi pi-search"></span>
			</span>
			<button class="p-link">
				<span class="layout-topbar-item-text">Settings</span>
				<span class="layout-topbar-icon pi pi-cog"></span>
			</button>
			<button class="p-link">
				<span class="layout-topbar-item-text">User</span>
				<span class="layout-topbar-icon pi pi-user"></span>
			</button> -->
		</div>
	</div>

	<OverlayPanel ref="op" class="op-notificacoes" @hide="onNotificacoesHide">
		<div class="notificacoes" v-if="notificacoes.length == 0">
			Nenhuma notificação.
		</div>
		<div class="notificacoes" :style="'width: 350px'" v-else>
			<div class="notificacao" v-for="n of notificacoes" :key="n.id">
				<div :class="(n.data_leitura == null)?'notificacao-content notificacao-nova':'notificacao-content notificacao-lida'">
					<div class="notificacao-data">{{ $utils.formatDateTimeToBr(n.data) }}</div>
					<div class="notificacao-titulo" v-show="n.titulo">{{ n.titulo }}</div>
					<div class="notificacao-descricao" v-show="n.descricao">{{ n.descricao }}</div>
				</div>
				<div class="notificacao-acoes">
					<Button icon="pi pi-external-link" v-tooltip.top="'Acessar'" @click="acessarNotificacao(n)"></Button>
					<Button icon="pi pi-trash" v-tooltip.top="'Excluir'" @click="removerNotificacao(n)"></Button>
				</div>
			</div>
		</div>
	</OverlayPanel>

</template>

<script>
export default {
	data() {
		return {
			notificacoes: [],
			notificacoes_novas: 0,
		}
	},
	emits: [
		'menu-toggle'
	],
	mounted() {
		setInterval(this.carregarNotificacoes, 5000);
	},
	watch: {
		notificacoes: function(val) {
			this.notificacoes_novas = 0;
			for(const n of val) {
				if(n.data_leitura == null)
					this.notificacoes_novas++;
			}
		}
	},
    methods: {
        async carregarNotificacoes() {

            const response = await this.$api.get('/notificacoes');

            const dados = response.data;

            if(dados.success) {

				// TODO A MEDIDA QUE VAI CARREGANDO AS NOTIFICAÇÕES VERIFICA SE ELAS JÁ NÃO FORAM CARREGADAS ANTERIORMENTE, SE NÃO FOI, NOTIFICA VISUALMENTE.

                this.notificacoes = dados.data;

            }

			if(typeof response.version == "string" && response.version != this.$versao) {
				window.location.reload(true);
			}

        },
		async onNotificacoesHide() {

			let ids = [];
			for(const n of this.notificacoes) {
				if(n.data_leitura == null)
					ids.push(n.id);
			}

			if(ids.length > 0) {

				await this.$api.post('/notificacoes/ler',{"ids":ids});

				this.carregarNotificacoes();

			}

		},
		acessarNotificacao(notificacao) {
			this.removerNotificacao(notificacao);
			this.$router.push({ path: `/${notificacao.modulo}/${notificacao.modulo_id}` });
			this.$refs.op.hide();
		},
		async removerNotificacao(notificacao) {
			await this.$api.post('/notificacoes/' + notificacao.id,{"_method":"DELETE"});
			const idx = this.notificacoes.findIndex((e) => e.id == notificacao.id);

			if(idx != -1)
				this.notificacoes.splice(idx,1);

		},
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
    }
}
</script>

<style lang="scss">

.notificacao-titulo {
    font-weight: 500;
    margin-top: 0.2rem;
}

.notificacao-descricao {
    font-weight: 400;
    margin-top: 0.2rem;
}

.notificacao-data {
    font-weight: 300;
    font-size: 0.8em;
}

.notificacao {
    cursor: pointer;
	position: relative;
}

.notificacoes .notificacao {
    margin-top: 0.6em;
    border-top: 1px solid #ccc;
    padding-top: 0.5em;
}

.notificacoes .notificacao:first-child {
    margin-top: 0;
    border-top: none;
    padding-top: 0;
}

.notificacoes .notificacao-content {
	padding: 0.5em;
	transition: background-color 1s;
	background-color: #ffffff;
}

.notificacoes .notificacao-content.notificacao-nova {
	background-color: #f5f5f5;
}

.op-notificacoes .p-overlaypanel-content {
	padding: 0.5rem !important;
}

.notificacao-acoes {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
	display: none;
}

.notificacao:hover .notificacao-acoes {
	display: block;
}

.notificacao-acoes .p-button {
    background: none !important;
    border: none !important;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50% !important;
}

.notificacao-acoes .p-button:last-child {
    right: 0;
}

</style>
