import moment from 'moment-timezone';

export default class {

    static emptyStr(val) {
      if(typeof val == "undefined" || typeof val == "object" && val == null)
        return "";
      return val.toString();
    }

    static valOrNull(val) {
      if(typeof val == "undefined" || val == null)
        return null;
      return val;
    }

    static formatPhone(phone) {
      if(typeof phone != "string")
        phone = "";
      phone = phone.replace(/[^0-9]/g,"");
      if(phone == "")
        return "";
      return '(' + phone.substr(0,2) + ') ' + phone.substr(2, phone.length -6) + '-' + phone.substr(-4);
    }

    static formatCpf(cpf) {
      if(typeof cpf != "string")
        return "";
      
      cpf = cpf.replace(/[^0-9]/g,'');

      if(cpf.length != 11)
        return "";

      return cpf.substr(0,3) + "." + cpf.substr(3,3) + "." + cpf.substr(6,3) + "-" + cpf.substr(9);
    }

    static formatCpfCnpj(cpf) {
      if(typeof cpf != "string")
        return "";
      
      cpf = cpf.replace(/[^0-9]/g,'');

      if(cpf.length != 11 && cpf.length != 14)
        return "";

      if(cpf.length == 11)
        return cpf.substr(0,3) + "." + cpf.substr(3,3) + "." + cpf.substr(6,3) + "-" + cpf.substr(9);
      
      return cpf.substr(0,2) + "." + cpf.substr(2,3) + "." + cpf.substr(5,3) + "/" + cpf.substr(8, 4) + "-" + cpf.substr(12);
    }

    static formatCurrency(val) {
        if(typeof val == "string")
            val = parseFloat(val);
        else if(typeof val != "number")
            return null;

        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val);
    }

    static formatDecimal(val) {
      if(typeof val == "string")
          val = parseFloat(val);
      else if(typeof val != "number")
          return null;

      val = new Intl.NumberFormat('pt-BR').format(val);

      if(val.indexOf(",") == -1) {
        val += ',00';
      } else if(val.indexOf(",") == (val.length - 2)) {
        val += '0';
      }

      return val;
    }

    static formatDateTimeToBr(date) {
        if(typeof date != "string" || date == "")
            return null;

        date = date.split(" ");

        return date[0].split("-").reverse().join("/") + " " + date[1];
    }

    static formatDateToBr(date) {
        if(typeof date != "string" || date == "")
            return null;
        return date.split("-").reverse().join("/");
    }

    static formatDateToMysql(date) {
        if(typeof date != "string" || date == "")
            return null;
        return date.split("/").reverse().join("-");
    }

    static getStdObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    static isCPF(strCPF) {
        strCPF = strCPF.replace(/[^0-9]/g, "");
  
        var i;
        var Soma;
        var Resto;
        Soma = 0;
  
        if (strCPF == "00000000000") return false;
  
        for (i = 1; i <= 9; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;
  
        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10))) return false;
  
        Soma = 0;
        for (i = 1; i <= 10; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
  
        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11))) return false;
  
        return true;
    }

    static isCNPJ(cnpj) {
        var i;
  
        cnpj = cnpj.replace(/[^\d]+/g, "");
  
        if (cnpj == "") return false;
  
        if (cnpj.length != 14) return false;
  
        // Elimina CNPJs invalidos conhecidos
        if (
          cnpj == "00000000000000" ||
          cnpj == "11111111111111" ||
          cnpj == "22222222222222" ||
          cnpj == "33333333333333" ||
          cnpj == "44444444444444" ||
          cnpj == "55555555555555" ||
          cnpj == "66666666666666" ||
          cnpj == "77777777777777" ||
          cnpj == "88888888888888" ||
          cnpj == "99999999999999"
        )
          return false;
  
        // Valida DVs
        var tamanho = cnpj.length - 2;
        var numeros = cnpj.substring(0, tamanho);
        var digitos = cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
  
        for (i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2) pos = 9;
        }
  
        var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  
        if (resultado != digitos.charAt(0)) return false;
  
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
  
        for (i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2) pos = 9;
        }
  
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  
        if (resultado != digitos.charAt(1)) return false;
  
        return true;
    }

    static daysFromNow(date) {

      if(!date)
        return -1;

      const end = moment(date, true).tz("America/Sao_Paulo");
      const now = moment().tz("America/Sao_Paulo");

      const duration = moment.duration(end.diff(now));
      
      return parseInt(duration.asDays());

    }

    static inputNumberFocus(event) {
      event.target.setSelectionRange(0, event.target.value.length);
    }

}