import Auth from './auth';
import axios from 'axios';

export default class {

    constructor(api) {
        this.urlApi = api;
        this.authObj = new Auth(this.urlApi);
    }

    auth() {
        return this.authObj;
    }

    url() {
        return this.urlApi;
    }

    headers() {
        return { 
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + this.auth().token(),
        };
    }

    get(endpoint/*, data = null*/) {

        if(typeof endpoint != "string")
            return null;

        //TRATAR PARAMS typeof data != object || data == null

        return axios({
            method: 'get',
            url: this.urlApi + endpoint,
            headers: this.headers(),
        });

    }

    post(endpoint, data = null) {

        if(typeof endpoint != "string" || typeof data != "object" || data == null)
            return null;

        return axios({
            method: 'post',
            url: this.urlApi + endpoint,
            headers: this.headers(),
            "data" : data
        });

    }

}