import Crypto from 'crypto-js';
import Cookie from 'js-cookie';
import uuid from 'uuid';
import axios from 'axios';

export default class {

    constructor(api) {
        this.cookieName = "MMKeyEnc";
        this.storageKey = "MMKeyAuth";
        this.encryptionToken = this.cryptToken();
        this.currentUser = null;
        this.urlApi = api;

        this.logged();
    }

    cryptToken() {

        var encryptionToken = Cookie.get(this.cookieName);

        if(!encryptionToken) {
            encryptionToken = uuid.v4();
            Cookie.set(this.cookieName, encryptionToken, { secure: false, expires: 1 }); //ALTERAR EM PRODUÇÃO
        }

        return encryptionToken;

    }

    logged(credencials = null, callback = null) {

        const self = this;

        if(credencials == null) { //obter usuário logado

            axios({
                method: 'get',
                url: this.urlApi + '/isLogged',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + this.token()
                }
            }).then(function (response) {

                if(response.data.success) {
                    self.currentUser = response.data.user;
                } else {
                    // The store will be reset if logged fails.
                    window.localStorage.removeItem(self.storageKey);
                    self.currentUser = null;

                }

                if(typeof callback == "function")
                    callback(response.data);

            }).catch(function (err) {

                // The store will be reset if logged fails.
                window.localStorage.removeItem(self.storageKey);
                self.currentUser = null;


                if(typeof callback == "function") {
                    err.response.data.success = false;
                    callback(err.response.data);
                }
    
            });
        
        } else { // logar usuário

            // The store will be reset if logged fails.
            window.localStorage.removeItem(self.storageKey);
            self.currentUser = null;

            axios({
                method: 'post',
                url: this.urlApi + '/login',
                headers: { 
                    'Accept': 'application/json'
                },
                data : credencials
            }).then(function (response) {

                if(response.data.success) {

                    // Encrypt the store using our encryption token stored in cookies.
                    const store = Crypto.AES.encrypt(response.data.token, self.encryptionToken).toString();

                    // Save the encrypted store in local storage.
                    window.localStorage.setItem(self.storageKey, store);

                    self.currentUser = response.data.user;

                }

                if(typeof callback == "function")
                    callback(response.data);

            }).catch(function (err) {

                if(typeof callback == "function") {
                    err.response.data.success = false;
                    callback(err.response.data);
                }
    
            });
        
        }

    }

    token() {

        // Get the store from local storage.
        const store = window.localStorage.getItem(this.storageKey);

        if (store) {
            try {
                // Decrypt the store retrieved from local storage
                // using our encryption token stored in cookies.
                const bytes = Crypto.AES.decrypt(store, this.encryptionToken);
                return bytes.toString(Crypto.enc.Utf8);
            } catch (e) {
                // The store will be reset if decryption fails.
                this.currentUser = null;
                window.localStorage.removeItem(this.storageKey);
                console.error(e);
            }
        }

        return null;

    }

    logout(callback = null) {

        axios({
            method: 'get',
            url: this.urlApi + '/logout',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.token()
            }
        }).then(function (response) {

            if(typeof callback == "function")
                callback(response.data);

        }).catch(function (err) {

            if(typeof callback == "function") {
                err.response.data.success = false;
                callback(err.response.data);
            }

        });

        // The store will be reset.
        window.localStorage.removeItem(this.storageKey);
        this.currentUser = null;
        return true;

    }

    // TODO Específico para esse projeto
    userCan(permission) {

        if(this.currentUser == null || !Array.isArray(this.currentUser.permissao_item.permissoes))
            return false;

        const regex = permission.indexOf("*") != -1;
        
        if(regex) {
            var condition = new RegExp(permission);
        }

        return (this.currentUser.permissao_item.permissoes.filter(function (el) {
            if(regex)
                return condition.test(el);
            else
                return el == permission;
        })).length > 0;

    }

}