<template>
	<div class="layout-menu-container">
		<AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" />
	</div>
	<div id="versao">
		Versão {{ versao }}
	</div>
</template>

<script>
import AppSubmenu from './AppSubmenu';

export default {
	props: {
		model: Array
	},
	emits: [
		'menuitem-click'
	],
	data() {
		return {
			versao: ""
		}
	},
	watch: {
		'$versao': {
			handler: function (versao) {				
				this.versao = versao;
			},
			immediate: true
		}
	},
    methods: {
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        }
    },
	components: {
		'AppSubmenu': AppSubmenu
	}
}
</script>

<style lang="scss" scoped>

	#versao {
		color: #6c757d;
		width: 100%;
		text-align: center;
		margin-top: 2em;
		font-size: 0.9em;
	}

</style>